/* eslint-disable react/prop-types */

import React, { memo } from 'react'

import TrackingProvider from '@aller/shared/components/BlinkLabrador/TrackingProvider'
import Sticky from '@aller/shared/components/LoganAds/Sticky'
import Topbanner from '@aller/shared/components/LoganAds/Topbanner'
import GridRow from '@aller/shared/components/GridRow'
import Section from '@aller/shared/components/Section'
import FourFourFourCard from '@aller/shared/components/LoganStructures/FourFourFourCard'
import DesktopBanner from '@aller/shared/components/LoganAds/DesktopBanner'

import { ConfigElement } from '../../../config/sol/subpages/config'
import { Header } from './Header'

interface Props {
	pageConfig: ConfigElement
}

const HoroscopeDesktop: React.FC<Props> = ({ pageConfig }) => {
	return (
		<Section>
			<TrackingProvider>
				<Topbanner pos={1} />
				<GridRow>
					<Header pageConfig={pageConfig} />
				</GridRow>
				<Sticky pos={1} isSkyscraper />
				<GridRow>
					<FourFourFourCard type="grow" />
					<FourFourFourCard type="grow" />
					<FourFourFourCard type="grow" />
					<FourFourFourCard type="grow" />
				</GridRow>
				<DesktopBanner pos={1} />
			</TrackingProvider>
		</Section>
	)
}

export default memo(HoroscopeDesktop)
