export default function removeEmptyArticles(children: any) {
	return children.filter((child: any) => {
		if (child && child.title === 'Empty article - Title') {
			return false
		}

		if (child && child.children) {
			child.children = removeEmptyArticles(child.children)
			if (child.children.length === 0) return false
		}

		if (child && child.children === null) {
			return false
		}

		return true
	})
}
