/* eslint-disable react/prop-types */

import React from 'react'

import { Article } from '@aller/shared/graphql/types'
import GridRow from '@aller/shared/components/GridRow'
import TwelveCard from '@aller/shared/components/LoganStructures/TwelveCard'
import { GetRowFunc } from '@aller/shared/components/getRow'

interface ArticleRowProps {
	deskedRow?: Article[]
	getRow?: GetRowFunc
	onlyTwoInRow?: boolean
}

// desktedRow: Desk'ed row from labrador
// getRow: Function tht returns a React Comp. with articles from store
const ArticleRow: React.FC<ArticleRowProps> = ({ deskedRow, getRow, onlyTwoInRow = false }) => {
	if (!deskedRow || deskedRow.length === 0) {
		return getRow ? getRow(0, onlyTwoInRow) : <TwelveCard />
	}

	return <GridRow>{deskedRow}</GridRow>
}

export default ArticleRow
