/* eslint-disable no-underscore-dangle */

import { StandardizedArticlePreviewFormat } from '@aller/shared/utils/standardize-articles/types'

import { standardizeArticleFromLabrador } from '@aller/shared/utils/standardize-articles/from-labrador'
import { Labrador_Front } from '@aller/shared/graphql/types' // eslint-disable-line camelcase
import cleanNullValues from '@aller/shared/utils/labrador/clean-null-values'

import { fetchAndStandardizeHarvester } from '@aller/shared/services/harvester'

type ResultTypes = StandardizedArticlePreviewFormat | StandardizedArticlePreviewFormat[] | null

export default async function extractFromLabrador(
	labradorFront: Labrador_Front, // eslint-disable-line camelcase
	rowModifier: Function,
): Promise<StandardizedArticlePreviewFormat[]> {
	const { rows: labradorRows } = labradorFront
	if (!labradorRows) {
		return []
	}

	const splitRows = true
	const modifiedRows = rowModifier ? rowModifier(labradorRows, splitRows) : labradorRows

	const promises = []
	for (const row of modifiedRows) {
		promises.push(parseChildren(row.children))
	}

	return flattenResult(await Promise.all(promises))
}

const flattenResult = (promiseResult: ResultTypes[]): StandardizedArticlePreviewFormat[] => {
	let content: StandardizedArticlePreviewFormat[] = []

	promiseResult.forEach(newContent => {
		if (!newContent) {
			return
		}

		if (Array.isArray(newContent)) {
			content = content.concat(newContent)
			return
		}

		content.push(newContent)
	})

	return content
}

async function parseChildren(children: any[]): Promise<ResultTypes> {
	if (!Array.isArray(children)) {
		return null
	}

	if (!children[0]) {
		return null
	}

	const filteredProps = { ...children[0] }
	cleanNullValues(filteredProps)

	if (filteredProps.__typename === 'Labrador_ArticlePreview') {
		return standardizeArticleFromLabrador(filteredProps)
	}

	if (filteredProps.__typename === 'Labrador_HarvesterSearch') {
		return fetchAndStandardizeHarvester(filteredProps)
	}

	return null
}
