const query = `
	query($id: Int!) {
		labrador {
			front(id: $id) {
			id
			siteId
			name
			title
			description
			noIndex
			userName
			userEmail
			noFollow
			published
			path: publishedURL
			rows {
				width
				__typename
				viewportVisibility
				children {
				__typename
				... on Labrador_Column {
					width
					children {
					... on Labrador_Row {
						width
						__typename
						viewportVisibility
						children {
						__typename
						... on Labrador_Column {
							width
							children {
							... on Labrador_Row {
								width
								__typename
								viewportVisibility
								children {
								__typename
								... on Labrador_Column {
									width
									children {
									... on Labrador_Row {
										width
										__typename
										viewportVisibility
									}
									}
								}
								... on Labrador_WolverineAd {
									type
								}
								... on Labrador_ArticlePreview {
									id
									title
									subtitle
									teaserTitle
									publishedURL
									publishedFullURL
									width
									imageURL
									textOverlayBackground
									contentMarketingPublisher
									image {
									id
									whRatio
									cropH
									cropW
									url
									x
									y
									}
									tags
									label
									metadata {
									showHeroPreview
									isMeninger
									showSubtitle
									hideImage
									isRecommended
									boxBackground {
										desktop
									}
								}
								}
								... on Labrador_CustomElement {
									elementType
								}
								... on Labrador_JWVideo {
									videoID
									title
									playerID
									imageURL
									width
								}
								... on Labrador_HarvesterSearch {
									limit
									offset
									q
									includeAnyTags
									includeAllTags
									excludeTags
									includeHosts
									excludeHosts
									startDate
									endDate
									url
									excludeUrl
									excludeDupes
									excludePaywall
									includePaywallOnly
									toExternalId
									byExternalId
									excludeExternalId
									includeTagsOccurrence
									tagFields
								}
								}
							}
							}
						}
						... on Labrador_WolverineAd {
							type
						}
						... on Labrador_ArticlePreview {
							id
							title
							subtitle
							teaserTitle
							publishedURL
							publishedFullURL
							width
							imageURL
							textOverlayBackground
							contentMarketingPublisher
							image {
							id
							whRatio
							cropH
							cropW
							url
							x
							y
							}
							tags
							label
							metadata {
							showHeroPreview
							isMeninger
							showSubtitle
							hideImage
							isRecommended
							boxBackground {
								desktop
							}
						}
						}
						... on Labrador_CustomElement {
							elementType
						}
						... on Labrador_JWVideo {
							videoID
							title
							playerID
							imageURL
							width
						}
						... on Labrador_HarvesterSearch {
							limit
							offset
							q
							includeAnyTags
							includeAllTags
							excludeTags
							includeHosts
							excludeHosts
							startDate
							endDate
							url
							excludeUrl
							excludeDupes
							excludePaywall
							includePaywallOnly
							toExternalId
							byExternalId
							excludeExternalId
							includeTagsOccurrence
							tagFields
						}
						}
					}
					}
				}
				... on Labrador_WolverineAd {
					type
				}
				... on Labrador_ArticlePreview {
					id
					title
					subtitle
					teaserTitle
					publishedURL
					publishedFullURL
					width
					imageURL
					textOverlayBackground
					contentMarketingPublisher
					image {
					id
					whRatio
					cropH
					cropW
					url
					x
					y
					}
					tags
					label
					metadata {
					showHeroPreview
					isMeninger
					showSubtitle
					hideImage
					isRecommended
					boxBackground {
						desktop
					}
				}
				}
				... on Labrador_CustomElement {
					elementType
				}
				... on Labrador_JWVideo {
					videoID
					title
					playerID
					imageURL
					width
				}
				... on Labrador_HarvesterSearch {
					limit
					offset
					q
					includeAnyTags
					includeAllTags
					excludeTags
					includeHosts
					excludeHosts
					startDate
					endDate
					url
					excludeUrl
					excludeDupes
					excludePaywall
					includePaywallOnly
					toExternalId
					byExternalId
					excludeExternalId
					includeTagsOccurrence
					tagFields
        }
				}
			}
			}
		}
		}
	`

export async function getLabradorData(isServer: boolean, labradorFrontId: number): Promise<any> {
	const fetcherUrl = isServer
		? 'http://wolverine-fetcher.prod.medialaben.no/query'
		: '/app/wolverine-fetcher/query'

	const result = await fetch(fetcherUrl, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			query,
			variables: {
				id: labradorFrontId,
			},
		}),
	})

	const data = await result.json()
	return (data && data.data && data.data.labrador && data.data.labrador.front) || {}
}
