import { HarvesterSearchParams, divideQParam } from '@aller/shared/services/harvester'

export const buildHarvesterQueryObj = (
	category: string,
	subcategory: string,
	search: string,
	conf: HarvesterSearchParams,
): HarvesterSearchParams => {
	const defaultConf = {
		limit: 200,
		excludeDupes: 95,
		excludeTags: 'utenlandske',
		excludePaywall: 'true',
		includeAllTags: category,
	} as HarvesterSearchParams

	if (subcategory) {
		defaultConf.includeAllTags += `,${subcategory}`
	}

	if (search) {
		defaultConf.q = search
	}

	const mergedConf = { ...defaultConf, ...conf }
	mergedConf.q = divideQParam(mergedConf.q)

	return mergedConf
}
