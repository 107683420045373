import React, { useEffect } from 'react'
import { WithRouterProps } from 'next/dist/client/with-router'

import solTheme from '@aller/theming/themes/sol'
import Layout from '@aller/shared/components/LoganLayout/Layout'
import SOLHeader from '@aller/shared/components/header/SOLHeader'
import SOLFooter from '@aller/shared/components/footer/SOLFooter'
import Metadata from '@aller/shared/components/Metadata'
import { useArticleDispatch } from '@aller/shared/store/ArticleStore'
import { StandardizedArticlePreviewFormat } from '@aller/shared/utils/standardize-articles/types'
import { fetchAndStandardizeHarvester } from '@aller/shared/services/harvester'
import ScoreCard from '@aller/shared/components/ScoreCard'
import checkForApp from '@aller/shared/utils/check-for-app'
import getRow from '@aller/shared/components/getRow'

import { ConfigElement } from '../../../config/sol/subpages/config'
import { buildHarvesterQueryObj } from './buildHarvesterQueryObj'
import { getLabradorData } from './getLabradorData'
import rowModifier from '../rowModifier'
import withData from '../../../lib/withData'
import extractFromLabrador from './extractFromLabrador'

import Draw from './Draw'

interface SetArticlesProps {
	articles: StandardizedArticlePreviewFormat[]
}

const SetArticles = ({ articles }: SetArticlesProps) => {
	const dispatch: any = useArticleDispatch()

	useEffect(() => {
		if (articles && articles.length > 0) {
			dispatch({
				type: 'SET_ARTICLES',
				articles,
			})
		}
	}, [articles]) // eslint-disable-line react-hooks/exhaustive-deps

	return null
}

interface SubPageProps extends WithRouterProps {
	isApp: boolean
	pageConfig: ConfigElement
	articles: StandardizedArticlePreviewFormat[]
	metadata: MetaProps
	isMobile: boolean
	isTablet: boolean
	withDataFinished: boolean
}

interface MetaProps {
	pathname: string
	siteName: string
	section: string
}

const SubPage = ({
	isApp,
	withDataFinished,
	isMobile,
	isTablet,
	pageConfig,
	metadata,
	articles,
}: SubPageProps) => {
	if (!withDataFinished) {
		return null
	}

	return (
		<Layout
			theme={solTheme}
			isMobile={isMobile}
			isApp={isApp}
			header={isApp ? null : <SOLHeader />}
			footer={<SOLFooter />}
		>
			<ScoreCard />
			<SetArticles articles={articles} />
			<Metadata
				publishedURL={metadata.pathname}
				description={pageConfig.description || pageConfig.title}
				title={pageConfig.title}
				domain="sol.no"
				siteCodeName="sol"
				section={metadata.section}
				siteName={metadata.siteName}
				twitter="soldotno"
				facebookPages={['125931433588']}
				isTablet={isTablet}
			>
				<script src="//cl-eu2.k5a.io/61828d9316dbc70bd27919a2.js" id="kilkaya" />
			</Metadata>

			<Draw getRow={getRow} pageConfig={pageConfig} />
		</Layout>
	)
}

interface InitialProps extends WithRouterProps {
	req: Request
	res: any
	pageConfig: ConfigElement
	query: Query
}

interface Query extends PathSegments {
	pageConfig: ConfigElement
	siteName: string
	urlQuery: string
	pathname: string
}

export interface PathSegments {
	category: string
	subcategory: string
	search: string
}

SubPage.getInitialProps = async ({
	req,
	res,
	query: { category = '', subcategory = '', search = '', pageConfig, siteName, pathname },
}: InitialProps) => {
	const isServer = !!req

	const isApp = req ? checkForApp('sol', req.headers) : false

	const pathSegments = { category, subcategory, search }
	const { labradorFrontId, harvester: harvesterSearch = {} } = pageConfig
	let articles: StandardizedArticlePreviewFormat[] = []

	if (labradorFrontId) {
		const labradorFront = await getLabradorData(isServer, labradorFrontId)
		const labAndPossiblyHarvesterArticles = await extractFromLabrador(labradorFront, rowModifier)
		articles = articles.concat(labAndPossiblyHarvesterArticles)
		if (category === 'horoskop' && isApp) {
			articles = manipulateHoroscopeLinksInApp(articles)
		}
	}

	if (pageConfig.harvester) {
		const queryObj = buildHarvesterQueryObj(category, subcategory, search, harvesterSearch)
		const harvesterArticles = await fetchAndStandardizeHarvester(queryObj)
		articles = articles.concat(harvesterArticles)
	}

	if (res) {
		const pathStr = Object.values(pathSegments)
			.filter((e) => e)
			.join('-')
		res.setHeader('cache-control', 'max-age=600')
		res.setHeader('x-cache-channel', `logan-sol-${pathStr}`)
	}

	return {
		isApp,
		pageConfig,
		articles,
		metadata: {
			pathname,
			siteName,
			section: category,
		},
	}
}

const manipulateHoroscopeLinksInApp = (articles: StandardizedArticlePreviewFormat[]) => {
	return articles.map((a) => {
		if (a.url) {
			a.url += '&spb=0' // &spb=0 (Show Partner Box) is to hide our header on our partners pages
		}
		return a
	})
}

export default withData(SubPage)
