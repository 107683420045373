/* eslint-disable camelcase */

import { percentageToGridValues } from '@aller/shared/utils/percentage-to-grid-values'

import { Labrador_Row, Maybe } from '@aller/shared/graphql/types'
import matchNestedStructure from '../../lib/matchNestedStructure'
import findAndReplaceProperties from '../../lib/findAndReplaceProperties'
import removeEmptyArticles from '../../lib/removeEmptyArticles'
import fillEmptyWidths from '../../lib/fillEmptyWidths'
import extractComponents from '../../lib/extractComponents'

export default function rowModifier(
	rows: Maybe<Maybe<Labrador_Row>[]>,
	splitRows: boolean,
): Maybe<Maybe<Labrador_Row>[]> {
	// Find and remove empty articles
	let modifiedRows = removeEmptyArticles(rows)

	// Fill in any missing widths
	modifiedRows = fillEmptyWidths(modifiedRows, modifiedRows.length)

	// Find all width properties and create column properties on the same element
	modifiedRows = findAndReplaceProperties(
		modifiedRows,
		'width',
		(width: number) => {
			return percentageToGridValues(width)
		},
		'column',
	)

	if (splitRows) {
		modifiedRows = extractComponents(modifiedRows, [
			'Labrador_ArticlePreview',
			'Labrador_HarvesterSearch',
		])
		modifiedRows = modifiedRows.map((rowChild: any) => {
			return { __typename: 'Labrador_Row', children: [rowChild] }
		})
	} else {
		// Find the "topnormal" structure and copy all the parent widths to the article preview
		modifiedRows = matchNestedStructure(
			modifiedRows,
			['Labrador_Row', 'Labrador_Column', 'Labrador_Row', 'Labrador_ArticlePreview'],
			'topnormal',
			{ originalProperty: 'width', newProperty: 'parentWidth' },
		)
	}

	return modifiedRows || []
}
