/* eslint-disable react/prop-types */

import React, { memo } from 'react'
import styled from 'styled-components'

import TrackingProvider from '@aller/shared/components/BlinkLabrador/TrackingProvider'
import Sticky from '@aller/shared/components/LoganAds/Sticky'
import Topbanner from '@aller/shared/components/LoganAds/Topbanner'
import GridRow from '@aller/shared/components/GridRow'
import Section from '@aller/shared/components/Section'
import LazySection from '@aller/shared/components/LazySection'

import { articleStoreCount, useArticleStore } from '@aller/shared/store/ArticleStore'

import NetboardWithFriend from '@aller/shared/components/LoganAds/NetboardWithFriend'
import CustomCardStructure from '@aller/shared/components/LoganStructures/CustomCardStructure'
import DesktopBanner from '@aller/shared/components/LoganAds/DesktopBanner'
import TwelveCard from '@aller/shared/components/LoganStructures/TwelveCard'
import { GetRowFunc } from '@aller/shared/components/getRow'

import ArticleRow from '../ArticleRow'
import { ConfigElement } from '../../../config/sol/subpages/config'
import { Header } from './Header'

const StyledLazySection = styled(LazySection)`
	padding-bottom: 0;
	padding-top: 0;
	min-height: 0;
`

interface AdCountersProps {
	banner: number
	netboard: number
	medRect: number
}

interface DynamicArticleListProps {
	getRow: GetRowFunc
	adCounters: AdCountersProps
}

const DynamicArticleList = ({ getRow, adCounters }: DynamicArticleListProps) => {
	const store: any = useArticleStore()
	const articlesLeft = store.articles.length - articleStoreCount()
	const chunkSize = 15
	const numChunks = Math.floor(articlesLeft / chunkSize)

	const { banner, netboard, medRect } = adCounters

	const Chunks = []
	for (let i = 0; i < numChunks; i += 1) {
		const rows = (
			<TrackingProvider>
				{i === 0 && <Sticky pos={1} />}
				<DesktopBanner pos={banner + i} />
				<ArticleRow getRow={getRow} />
				<ArticleRow getRow={getRow} />
				<NetboardWithFriend pos={netboard + i} />
				<ArticleRow getRow={getRow} />
				<GridRow>
					<CustomCardStructure
						type="grow"
						rowStructure={[
							{ type: 'article', width: 4 },
							{ type: 'article', width: 4 },
							{ type: 'medium-rectangle', pos: medRect + i, width: 4 },
						]}
					/>
				</GridRow>
				<ArticleRow getRow={getRow} />
				{i === numChunks - 1 && <LeftOverArticles getRow={getRow} />}
			</TrackingProvider>
		)
		Chunks.push(<StyledLazySection key={`section-${i}`}>{rows}</StyledLazySection>)
	}

	return <>{Chunks}</>
}

interface LeftOverArticlesProps {
	getRow: GetRowFunc
}

const LeftOverArticles = ({ getRow }: LeftOverArticlesProps) => {
	const store: any = useArticleStore()
	const articlesLeft = store.articles.length - articleStoreCount()
	const half = Math.floor(articlesLeft / 2)

	const Rows = [] as any
	for (let i = 0; i <= half; i += 1) {
		Rows.push(
			<StyledLazySection key={`leftover-${i}`}>
				<ArticleRow getRow={getRow} onlyTwoInRow />
			</StyledLazySection>,
		)
	}

	if (articlesLeft % 2) {
		Rows.push(
			<StyledLazySection key="leftoverlast">
				<TwelveCard />
			</StyledLazySection>,
		)
	}

	return <>{Rows}</>
}

interface Props {
	getRow: GetRowFunc
	pageConfig: ConfigElement
}

const DrawDesktop: React.FC<Props> = ({ getRow, pageConfig }) => {
	return (
		<>
			<Section>
				<TrackingProvider>
					<Topbanner pos={1} />
					<GridRow>
						<Header pageConfig={pageConfig} />
					</GridRow>
					<Sticky pos={1} isSkyscraper />
					<ArticleRow getRow={getRow} />
					<GridRow>
						<CustomCardStructure
							type="grow"
							rowStructure={[
								{ type: 'article', width: 4 },
								{ type: 'article', width: 4 },
								{ type: 'medium-rectangle', pos: 1, width: 4 },
							]}
						/>
					</GridRow>
					<ArticleRow getRow={getRow} />
					<NetboardWithFriend pos={1} />
					<ArticleRow getRow={getRow} />
				</TrackingProvider>
			</Section>

			<StyledLazySection>
				<TrackingProvider>
					<GridRow>
						<CustomCardStructure
							type="grow"
							rowStructure={[
								{ type: 'article', width: 4 },
								{ type: 'article', width: 4 },
								{ type: 'medium-rectangle', pos: 2, width: 4 },
							]}
						/>
					</GridRow>
					<ArticleRow getRow={getRow} />
					<NetboardWithFriend pos={2} />
					<ArticleRow getRow={getRow} />
					<GridRow>
						<CustomCardStructure
							type="grow"
							rowStructure={[
								{ type: 'article', width: 4 },
								{ type: 'article', width: 4 },
								{ type: 'medium-rectangle', pos: 3, width: 4 },
							]}
						/>
					</GridRow>
					<ArticleRow getRow={getRow} />
				</TrackingProvider>
			</StyledLazySection>

			<DynamicArticleList adCounters={{ banner: 1, netboard: 3, medRect: 4 }} getRow={getRow} />
		</>
	)
}

export default memo(DrawDesktop)
