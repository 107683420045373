import { Labrador_Row, Maybe } from '@aller/shared/graphql/types'

export default function extractComponents(
	children: Maybe<Maybe<Labrador_Row>[]>,
	whitelist: string[],
	d: number = 0,
) {
	if (!children) return children
	let newRows: any[] = []

	children.forEach((child: any) => {
		if (whitelist.includes(child.__typename)) {
			newRows.push(child)
		} else if (child.children) {
			const childResults = extractComponents(child.children, whitelist, d + 1)

			if (childResults && childResults.length) {
				newRows = newRows.concat(childResults)
			}
		}
	})

	return newRows
}
