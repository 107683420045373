export default function fillEmptyWidths(children: any, numOfChildren: number) {
	const widths: number[] = []
	children.forEach((child: any) => {
		if (child.width) {
			widths.push(child.width)
		}
	})
	return children.map((child: any) => {
		if (!child.width) {
			const numOfMissingWidths = numOfChildren - widths.length
			const percentageLeft = 100 - widths.reduce((a, b) => a + b, 0)
			child.width = percentageLeft / numOfMissingWidths
			widths.push(child.width)
		}
		if (child.children) {
			child.children = fillEmptyWidths(child.children, child.children.length)
		}
		return child
	})
}
