/* eslint-disable react/prop-types */

import React, { memo } from 'react'

import XavierProvider from '@aller/shared/providers/XavierProvider'
import { useIsMobile } from '@aller/shared/hooks/useIsMobile'
import { useArticles } from '@aller/shared/store/ArticleStore'
import { GetRowFunc } from '@aller/shared/components/getRow'

import DrawDesktop from './DrawDesktop'
import DrawMobile from './DrawMobile'
import HoroscopeDesktop from './HoroscopeDesktop'
import { ConfigElement } from '../../../config/sol/subpages/config'

interface ContentProps {
	getRow: GetRowFunc
	pageConfig: ConfigElement
}

const Draw: React.FC<ContentProps> = ({ getRow, pageConfig }) => {
	// TODO: Fix this. The counter in the ArticleStore is not reset when hot reloading and
	// the first articles may not be displayed. Using the resetCount parameter can
	// cause some duplicate articles, only use it in dev
	useArticles(0, process.env.NODE_ENV === 'development', true)

	const isMobile = useIsMobile()
	if (isMobile) {
		return (
			<XavierProvider>
				<DrawMobile pageConfig={pageConfig} />
			</XavierProvider>
		)
	}

	if (pageConfig.id === 'horoskop') {
		return (
			<XavierProvider>
				<HoroscopeDesktop pageConfig={pageConfig} />
			</XavierProvider>
		)
	}

	return (
		<XavierProvider>
			<DrawDesktop getRow={getRow} pageConfig={pageConfig} />
		</XavierProvider>
	)
}

export default memo(Draw)
