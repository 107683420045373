/* eslint-disable react/prop-types */

import React, { memo } from 'react'
import styled from 'styled-components'

import { useArticleStore, articleStoreCount } from '@aller/shared/store/ArticleStore'

import TrackingProvider from '@aller/shared/components/BlinkLabrador/TrackingProvider'
import Banner from '@aller/shared/components/LoganAds/Banner'
import Section from '@aller/shared/components/Section'
import LazySection from '@aller/shared/components/LazySection'

import ArticleRow from '../ArticleRow'
import { ConfigElement } from '../../../config/sol/subpages/config'
import { Header } from './Header'

const StyledLazySection = styled(LazySection)`
	padding-bottom: 0;
	padding-top: 0;
`

interface AdCounterProps {
	bannerCounter: number
}

interface DynamicArticleListProps {
	adCounter: AdCounterProps
}

const DynamicArticleList = ({ adCounter }: DynamicArticleListProps) => {
	const store: any = useArticleStore()

	const { bannerCounter } = adCounter

	const articlesLeft = store.articles.length - articleStoreCount()
	const chunkSize = 3
	const numChunks = Math.ceil(articlesLeft / chunkSize)

	const Chunks = []
	for (let i = 0; i < numChunks; i += 1) {
		const rows = (
			<TrackingProvider>
				<ArticleRow />
				<Banner pos={bannerCounter + i} />
				<ArticleRow />
				<ArticleRow />
			</TrackingProvider>
		)
		Chunks.push(<StyledLazySection key={`section-${i}`}>{rows}</StyledLazySection>)
	}

	return <>{Chunks}</>
}

interface Props {
	pageConfig: ConfigElement
}

const DrawMobile: React.FC<Props> = ({ pageConfig }) => {
	return (
		<>
			<Section>
				<TrackingProvider>
					<Header pageConfig={pageConfig} />
					<ArticleRow />
					<Banner pos={1} />
					<ArticleRow />
					<ArticleRow />
				</TrackingProvider>
			</Section>

			<DynamicArticleList adCounter={{ bannerCounter: 2 }} />
		</>
	)
}

export default memo(DrawMobile)
