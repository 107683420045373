export default function matchNestedStructure(
	children: any,
	// An array containing the structure you are looking for, e.g. ['Labrador_Column', 'Labrador_Row', 'Labrador_ArticlePreview'].
	// This will be a match if it finds Column > Row > Article preview
	structure: any[],
	// A string that will be added as a property to the last element in the structure you are looking for
	identifier: string,
	// Copies properties from parent elements to the last element in the structure. E.g. { originalProperty: 'width', newProperty: 'parentWidth' }.
	// 'parentWidth' will be appended to the last element in the structure with an array of all of its parents 'width' values
	copyProperty?: any,
	depth: number = 0
) {
	return children.map((child: any) => {
		// Check if the current element has the same type as specified in the structure
		if (structure[depth] && child.__typename === structure[depth]) {
			// The structure is found if the last element in the structure array is a match
			if (structure.length - 1 === depth) {
				child.identifier = identifier
				if (copyProperty) child[copyProperty.newProperty] = copyProperty.newValues
				return child
			}

			// Call matchNestedStructure again on the children if there are any
			if (child.children) {
				// We only want properties copied from direct parents, not siblings of parents
				const copyPropertyClone = JSON.parse(JSON.stringify(copyProperty))
				if (copyPropertyClone) {
					if (child[copyPropertyClone.originalProperty]) {
						copyPropertyClone.newValues = Array.isArray(copyPropertyClone.newValues)
							? copyPropertyClone.newValues
							: []
						copyPropertyClone.newValues.push({
							type: child.__typename,
							value: child[copyPropertyClone.originalProperty]
						})
					}
				}

				child.identifier = null
				child.children = matchNestedStructure(
					child.children,
					structure,
					identifier,
					copyPropertyClone,
					depth + 1
				)
			}
		}
		return child
	})
}
