export default function findAndReplaceProperties(
	children: any,
	property: string,
	func: any,
	newProperty: string,
	deleteOriginal: boolean = false
) {
	return children.map((child: any) => {
		if (child[property]) {
			child[newProperty] = func(child[property])
			if (deleteOriginal) delete child[property]
		}
		if (child.children) {
			child.children = findAndReplaceProperties(
				child.children,
				property,
				func,
				newProperty,
				deleteOriginal
			)
		}
		return child
	})
}
