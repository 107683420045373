import React from 'react'
import styled from 'styled-components'
import { ConfigElement } from '../../../config/sol/subpages/config'

const Heading = styled.h2`
	text-transform: uppercase;
	margin: 0 -1.5rem 2.5rem 1.5rem;
	padding: 0 0 0.6rem;
	border-bottom: 0.5rem solid #455c7b;
	font-size: 3rem;
	font-weight: 800;
	display: inline-block;
`

const SubHeading = styled.div`
	font-size: 1.7rem;
	margin: -1.5rem 0 2rem 1.5rem;
`

interface Props {
	pageConfig: ConfigElement
}

export const Header = ({ pageConfig }: Props) => {
	return (
		<div>
			<Heading>{pageConfig.title}</Heading>
			{pageConfig.description && <SubHeading>{pageConfig.description}</SubHeading>}
		</div>
	)
}
